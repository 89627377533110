import React, { useEffect, useId, useState } from "react";
import {
  Button,
  Col,
  Form,
  Modal,
  Toast,
  ToastContainer,
} from "react-bootstrap";
import EmptyPage from "../../components/EmptyPage";
import { MemberWorkExtendedDTO } from "../../dtos/MemberWorkExtendedDTO";
import { NetworkCalls } from "../../NetworkCalls";
import { getOrganisation } from "../../service/LoadStorageData";
import FangkarteList from "../../components/FangkarteList";
import { FangkarteListItem } from "../../models/FangkarteListItem";
import DeleteDialog from "../../components/DeleteDialog";
import uuid from "react-uuid";
import MemberWorkAttendeeDTO from "../../dtos/MemberWorkAttendeeDTO";
import { Link } from "react-router-dom";
import SearchMember from "../../components/SearchMember";
import DateFormat from "../../utils/DateFormat";
import DateTime, { TimeConstraints } from "react-datetime";
import { Moment } from "moment/moment";
import { isMoment } from "moment-timezone";
import {
  APILoadingStatus,
  APIProvider,
  Map,
  Marker,
  useApiLoadingStatus,
} from "@vis.gl/react-google-maps";

export default function MemberWorkExtended() {
  function getInitialAppointment() {
    let date = new Date();
    return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() + 1,
      6,
      0,
      0,
      0
    );
  }

  const status = useApiLoadingStatus();

  useEffect(() => {
    console.log(`Status: ${status}`);
    if (status === APILoadingStatus.FAILED) {
      console.log(":(");

      return;
    }
  }, [status]);

  const [memberWorkData, setMemberWorkData] = useState<MemberWorkExtendedDTO[]>(
    []
  );
  const [selectedMemberWork, setSelectedMemberWork] = useState<
    MemberWorkExtendedDTO | undefined
  >();
  const [show, setShow] = useState<boolean>(false);
  const [appointment, setAppointment] = useState<Date>(getInitialAppointment());
  const [maxDate] = useState<Date>(
    new Date(
      new Date().getFullYear() + 1,
      new Date().getMonth(),
      new Date().getDay()
    )
  );
  const [title, setTitle] = useState<string>();
  const [additionalInformation, setAdditionalInformation] = useState<string>();
  const [maxParticipants, setMaxParticipants] = useState<number>();
  const [lat, setLat] = useState<number | undefined>();
  const [lng, setLng] = useState<number | undefined>();
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const [showDeleteAttendeeDialog, setShowDeleteAttendeeDialog] =
    useState<boolean>(false);
  const [memberWorkAttendees, setMemberWorkAttendees] = useState<
    MemberWorkAttendeeDTO[]
  >([]);
  const [showByGone, setShowByGone] = useState<boolean>(false);
  const [selectedAttendee, setSelectedAttendee] = useState<
    MemberWorkAttendeeDTO | undefined
  >(undefined);
  const [showMemberSearch, setShowMemberSearch] = useState<boolean>(false);
  const [actionSuccessful, setActionSuccessful] = useState<boolean>(false);

  const timeConstraints = { minutes: { step: 15 } } as TimeConstraints;

  const inactiveClasses = "nav-link";
  const activeClasses = "nav-link active";

  useEffect(() => {
    if (selectedMemberWork) {
      setTitle(selectedMemberWork.title);
      setLat(selectedMemberWork.latitude);
      setLng(selectedMemberWork.longitude);
      setAdditionalInformation(selectedMemberWork.additionalInformation);
      setMaxParticipants(selectedMemberWork.maxParticipants);

      setAppointment(selectedMemberWork.start);
    }
  }, [selectedMemberWork]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (tabIndex === 1) {
      loadMemberWorkAttendees();
    }
  }, [tabIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setAppointment(getInitialAppointment());

    const fetchData = async () => {
      setMemberWorkData(await NetworkCalls.getMemberWorkExtended(false));
    };
    fetchData().catch(console.error);
  }, []);

  const ModalDialog = () => {
    const modalId = useId();
    return (
      <Modal show={show} onHide={handleClose} id={modalId}>
        <Modal.Header closeButton>
          <Modal.Title>Arbeitsdienst einstellen</Modal.Title>
        </Modal.Header>
        <Modal.Body>{detailsPageMemberWork("map")}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            disabled={
              !title ||
              !maxParticipants ||
              maxParticipants < 1 ||
              !appointment ||
              !lat ||
              !lng
            }
            onClick={() => {
              if (maxParticipants && title && appointment && lat && lng) {
                persist({
                  title: title,
                  maxParticipants: maxParticipants,
                  start: getAppointmentWithSelectedTime(),
                  latitude: lat,
                  longitude: lng,
                  additionalInformation: additionalInformation,
                  organisationId: getOrganisation().id,
                }).then((r) => setShow(false));
              }
            }}
          >
            Speichern
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  // @ts-ignore
  const handleChange = (e) => {
    e.preventDefault();
    setAdditionalInformation(e.target.value);
  };

  const persist = async (dto: MemberWorkExtendedDTO) => {
    await NetworkCalls.addMemberWorkExtended(dto);
    setShowByGone(false);
    setMemberWorkData(await NetworkCalls.getMemberWorkExtended(false));
  };

  const update = async (dto: MemberWorkExtendedDTO) => {
    await NetworkCalls.updateMemberWorkExtended(dto);
    setShowByGone(false);
    setMemberWorkData(await NetworkCalls.getMemberWorkExtended(false));
  };

  const divStyle = {
    width: "100%",
    height: "300px",
  };

  const handleShow = () => {
    setShow(true);
    setAppointment(getInitialAppointment());
  };
  const handleClose = () => setShow(false);

  function createRows() {
    return memberWorkAttendees.map((elem) => {
      return (
        <tr key={uuid()} className="user-select-auto">
          <td>{elem.memberNumber}</td>
          <td>{elem.firstName}</td>
          <td>{elem.lastName}</td>
          <td>
            <Form.Check // prettier-ignore
              type={"checkbox"}
              id={`default-checkbox`}
              checked={elem.attended}
              onChange={async (checkbox) => {
                const memberWorkAttendance = {
                  memberNumber: elem.memberNumber,
                  memberWorkId: elem.memberWork.id!,
                  organisationId: elem.memberWork.organisationId!,
                };
                if (checkbox.target.checked) {
                  await NetworkCalls.addAttendanceToMemberWork(
                    memberWorkAttendance
                  );
                } else {
                  await NetworkCalls.removeAttendanceToMemberWork(
                    memberWorkAttendance
                  );
                }
                setActionSuccessful(true);
                loadMemberWorkAttendees();
              }}
            />
          </td>
          <td>
            <Button
              variant="danger"
              onClick={() => {
                setShowDeleteAttendeeDialog(true);
                setSelectedAttendee(elem);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-trash"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M4 7l16 0"></path>
                <path d="M10 11l0 6"></path>
                <path d="M14 11l0 6"></path>
                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
              </svg>
            </Button>
          </td>
        </tr>
      );
    });
  }

  function showParticipants() {
    return (
      <table className="table table-hover">
        <thead>
          <tr key={uuid()}>
            <th scope="col">Mitgliedsnummer</th>
            <th scope="col">Vorname</th>
            <th scope="col">Nachname</th>
            <th scope="col">Teilgenommen</th>
            <th scope="col">Entfernen</th>
          </tr>
        </thead>
        <tbody id="table-content">{createRows()}</tbody>
      </table>
    );
  }

  const loadMemberWorkAttendees = () => {
    if (selectedMemberWork) {
      NetworkCalls.getMemberWorkAttendees(selectedMemberWork).then((value) =>
        setMemberWorkAttendees(value)
      );
    }
  };

  const showContent = () => {
    return (
      <div className="page-body">
        <ToastContainer
          className="p-3"
          position={"bottom-end"}
          style={{ zIndex: 1 }}
        >
          <Toast
            bg={"info"}
            delay={3000}
            autohide
            show={actionSuccessful}
            onClose={() => setActionSuccessful(false)}
          >
            <Toast.Header closeButton={true}>
              <strong className="me-auto">Speichern erfolgreich</strong>
            </Toast.Header>
            <Toast.Body>Datensatz wurde erfolgreich gespeichert</Toast.Body>
          </Toast>
        </ToastContainer>
        <div className="container-xl pt-5">
          <div className="row row-deck row-cards">
            <div className="col-4">
              <FangkarteList
                onItemAdd={() => handleShow()}
                addNewElement={true}
                showSwitch={true}
                onSwitchChanged={async (elem) => {
                  setShowByGone(elem.target.checked);
                  setSelectedMemberWork(undefined);
                  setMemberWorkData(
                    await NetworkCalls.getMemberWorkExtended(
                      elem.target.checked
                    )
                  );
                }}
                listItems={createListItems()}
                onItemClicked={(item: MemberWorkExtendedDTO) => {
                  setSelectedMemberWork(item);
                  setLat(item.latitude);
                  setLat(item.longitude);
                  setTabIndex(0);
                }}
                title="Arbeitsdienste"
                exportListEnabled={false}
              />
            </div>
            <div className="col-8">
              <DeleteDialog
                onDeleteConfirm={() => {
                  setShowDeleteDialog(false);
                  if (selectedMemberWork) {
                    NetworkCalls.deleteMemberWorkExtended(
                      selectedMemberWork.id!
                    ).then((result) => {
                      setMemberWorkData(result);
                      setSelectedMemberWork(undefined);
                    });
                  }
                }}
                title="Löschen"
                message="Datensatz wirklich löschen?"
                show={showDeleteDialog}
                onHandleClose={() => setShowDeleteDialog(false)}
              />
              {selectedMemberWork ? showDetails() : <EmptyPage />}
              <SearchMember
                onClose={() => setShowMemberSearch(false)}
                onSelectedMembers={async (members) => {
                  if (members.length > 0 && selectedMemberWork) {
                    for (const member of members) {
                      await NetworkCalls.applyForMemberWork({
                        memberId: member.number,
                        lastName: member.lastname,
                        memberWorkId: selectedMemberWork.id!,
                      });
                    }
                    loadMemberWorkAttendees();
                  }
                }}
                show={showMemberSearch}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  function validateDateInput(currentDate?: Moment, selectedDate?: Moment) {
    if (!currentDate) return true;
    let now = new Date();
    return currentDate.isSameOrAfter(now) && currentDate.isBefore(maxDate);
  }

  function onDateTimeChange(value: Moment | string) {
    if (isMoment(value)) {
      let x = value.minute() % 15;
      if (x !== 0) {
        if (x > 7) {
          value.minute(value.minute() + 15 - x);
        } else {
          value.minute(value.minute() - x);
        }
      }

      let date = value.toDate();

      setAppointment(date);
      // setTime(date);
    } else {
      console.error("onDateTimeChange error", value);
    }
  }

  const detailsPageMemberWork = (mapId: string) => {
    return (
      <Form>
        <Form.Group>
          <Form.Label className="required">Titel des Arbeitsdienst</Form.Label>
          <Form.Control
            id="title"
            type="text"
            disabled={showByGone && !show}
            placeholder="Der Name des Arbeitsdiensts"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label className="required">
            Anzahl Teilnehmer maximal
          </Form.Label>
          <Form.Control
            type="number"
            disabled={showByGone && !show}
            placeholder="Anzahl Teilnehmer maximal"
            maxLength={2}
            max={99}
            value={maxParticipants}
            onChange={(e) => setMaxParticipants(Number(e.target.value))}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label className="required">
            Datum, wann der Arbeitsdienst stattfinden soll
          </Form.Label>
          <Col>
            <DateTime
              timeConstraints={timeConstraints}
              isValidDate={validateDateInput}
              locale={"de"}
              dateFormat={"DD.MM.yyyy"}
              timeFormat={"HH:mm"}
              value={appointment}
              utc={true}
              onChange={onDateTimeChange}
              initialValue={appointment}
            />
          </Col>
        </Form.Group>
        <Form.Group>
          <Form.Label className="required">Treffpunkt</Form.Label>
          <Col>
            <Map
              style={divStyle}
              defaultCenter={{
                lat: lat || 52,
                lng: lng || 9,
              }}
              defaultZoom={8}
              gestureHandling={"greedy"}
              disableDefaultUI={true}
            >
              <Marker
                position={{
                  lat: lat || 52,
                  lng: lng || 9,
                }}
                clickable={true}
                draggable={true}
                onDragEnd={(evt) => {
                  if (evt && evt.latLng) {
                    setLat(evt.latLng.lat());
                    setLng(evt.latLng.lng());
                  }
                }}
                title={"Treffpunkt"}
              />
            </Map>
          </Col>
        </Form.Group>
        <Form.Group>
          <Form.Label>Zusätzliche Informationen (Optional)</Form.Label>
          <Col>
            <Form.Control
              disabled={showByGone && !show}
              as="textarea"
              rows={3}
              value={additionalInformation}
              onChange={handleChange}
            />
          </Col>
        </Form.Group>
      </Form>
    );
  };

  const showDetails = () => {
    return (
      <div className="card">
        <div className="card-header">
          <ul className="nav nav-tabs card-header-tabs">
            <li className="nav-item">
              <Link
                to="#"
                className={tabIndex === 0 ? activeClasses : inactiveClasses}
                onClick={() => setTabIndex(0)}
              >
                Bearbeiten
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="#"
                className={tabIndex === 1 ? activeClasses : inactiveClasses}
                onClick={() => setTabIndex(1)}
              >
                Teilnehmer
              </Link>
            </li>
          </ul>
        </div>
        <DeleteDialog
          onDeleteConfirm={() => {
            setShowDeleteAttendeeDialog(false);
            if (selectedMemberWork && selectedAttendee) {
              NetworkCalls.removeMemberWorkAttendee(
                selectedMemberWork,
                selectedAttendee
              ).then((result) => {
                setMemberWorkAttendees(result);
              });
            }
          }}
          title={`${selectedAttendee?.firstName} ${selectedAttendee?.lastName} entfernen`}
          message={`${selectedAttendee?.firstName} ${selectedAttendee?.lastName} wirklich vom Arbeitsdienst entfernen?`}
          show={showDeleteAttendeeDialog}
          onHandleClose={() => setShowDeleteAttendeeDialog(false)}
        />
        <div className="card-body">
          {tabIndex === 0
            ? detailsPageMemberWork("detailMap")
            : showParticipants()}
        </div>
        {tabIndex === 1 || showByGone ? (
          <div className="card-footer">
            <div className="d-flex">
              <Button
                variant="primary"
                onClick={() => setShowMemberSearch(true)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-plus"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M12 5l0 14"></path>
                  <path d="M5 12l14 0"></path>
                </svg>
                Teilnehmer hinzufügen
              </Button>
            </div>
          </div>
        ) : (
          <div className="card-footer">
            <div className="d-flex">
              <Button
                onClick={() => {
                  if (selectedMemberWork && appointment) {
                    update({
                      id: selectedMemberWork.id,
                      title: title!,
                      maxParticipants: maxParticipants!,
                      start: getAppointmentWithSelectedTime(),
                      latitude: lat!,
                      longitude: lng!,
                      additionalInformation: additionalInformation,
                      organisationId: getOrganisation().id,
                    }).then((r) => setShow(false));
                  }
                }}
                variant="primary"
              >
                Speichern
              </Button>
              <Button
                className="btn btn-danger ms-auto"
                onClick={() => setShowDeleteDialog(true)}
              >
                Löschen
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  };

  const getAppointmentWithSelectedTime = () => {
    console.log("appointment: ", appointment, typeof appointment);
    appointment.setHours(
      appointment?.getHours() ?? 0,
      appointment?.getMinutes() ?? 0,
      0,
      0
    );
    return appointment;
  };

  const createListItems = () => {
    return memberWorkData.map((dto) => {
      let date = DateFormat.toDateOnlyReadableFormat(dto.start);
      return {
        title: dto.title,
        subTitle: date,
        originalElement: dto,
      } as FangkarteListItem;
    });
  };

  return (
    <div>
      <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY!}>
        {memberWorkData && memberWorkData.length > 0 ? (
          showContent()
        ) : (
          <EmptyPage addNewElement={true} onItemAdd={handleShow} />
        )}
        {ModalDialog()}
      </APIProvider>
    </div>
  );
}
